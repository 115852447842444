//Packages
import React from 'react'
import { navigate } from 'gatsby'

//Layouts
import { MainLayout } from '../../layout/MainLayout'

// Assets
import { isBrowser, isLoggedIn } from '../../utils/storage'
import LoginContainer from '../../containers/LoginContainer'

const AuthPage: React.FC = () => {
  return (
    <MainLayout>
      {!isLoggedIn() && <LoginContainer />}
      {isLoggedIn() && isBrowser() && navigate('/')}
    </MainLayout>
  )
}

export default AuthPage
